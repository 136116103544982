import React, {useEffect, useRef, useState} from 'react'
import "./Menu.scss"

import ScrollUpIcon from '../../assets/img/icons/scroll_up.png'
import BannerHappyHour from '../../assets/img/banner/happy hour english.jpg'
import BannerLunchTwo from '../../assets/img/banner/lunch formula english.jpg'
import BannerHappyHourAr from '../../assets/img/banner/happy hour arabic.jpg'
import BannerLunchTwoAr from '../../assets/img/banner/lunch offer arabic.jpg'
import OurMenuImg from '../../assets/img/our_menu.jpg'
import ReservationImg from '../../assets/img/reservation_img.jpg'
import RestaurantPolicyImg from '../../assets/img/restaurant_policy_img.jpg'
import {Category} from "../../interfaces/category.interface"
import {MenuItem} from "../../interfaces/menuItem.interface"
import CategoryDummyImg from "../../assets/img/dummy/category/category_test.jpg"

import pinIcon from '../../assets/img/icons/pin_icon.png';
import phoneIcon from '../../assets/img/icons/phone_icon.png';
import emailIcon from '../../assets/img/icons/email_icon.png';
import useFetch from '../../hooks/useFetch'
import * as events from "events";
import config from "../../i18n/config";
import $ from "jquery";

const defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'en';

interface IProps {
}

interface IState {
    playOrPause?: string;
}

interface CategorySorted {
    [key: string]: string;
}

interface MenuItemSorted {
    [key: string]: MenuItem[];
}


const Menu = () => {
    const {hash, pathname, search} = window.location;
    let menuListSorted: MenuItemSorted = {};
    let categorySorted: CategorySorted = {};
    let dataMenuItem: MenuItem[] = [];

    var menuPageNumber = 1;
    var menuAPIResponseLength = 0;

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };


    React.useEffect(() => {
        var scrollIcon = $("#scroll-up");
        window.addEventListener("scroll", () => {
            const currentScroll = window.scrollY;
            if (currentScroll < 150) {
                scrollIcon.fadeOut();
            } else {
                scrollIcon.fadeIn();
            }
        });
    });

    menuAPIResponseLength = 0;
    var {
        data,
        loading,
        error
    } = useFetch(`/api/menu-items?populate=*&locale=${defaultLocale}&pagination[page]=1&pagination[pageSize]=100&sort=price_sar`);
    dataMenuItem = data;

    dataMenuItem?.map((item: MenuItem) => {
        var arrayKey: string = item.attributes.category?.data?.id + "cat"
        if (menuListSorted.hasOwnProperty(arrayKey)) {
            menuListSorted[arrayKey].push(item)
        } else {
            menuListSorted[arrayKey] = [item]
        }
    })

    var {
        data,
        loading,
        error
    } = useFetch(`/api/menu-items?populate=*&locale=${defaultLocale}&pagination[page]=2&pagination[pageSize]=100&sort=price_sar`);
    dataMenuItem = data;

    dataMenuItem?.map((item: MenuItem) => {
        var arrayKey: string = item.attributes.category?.data?.id + "cat"
        if (menuListSorted.hasOwnProperty(arrayKey)) {
            menuListSorted[arrayKey].push(item)
        } else {
            menuListSorted[arrayKey] = [item]
        }
    })

    var {data, loading, error} = useFetch(`/api/categories?populate=*&locale=${defaultLocale}&sort=display_order`);
    const dataCategories = data;
    dataCategories?.map((item: Category) => {
        let arrayKey: string = item.id + "cat";
        categorySorted[arrayKey + ""] = item.attributes.title;
    })


    function handleFullscreenClick(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        let fullscreendiv = e.target as HTMLInputElement;
        fullscreendiv.style.display = "none";
    }
    function scrollToUp(e: React.MouseEvent<HTMLElement>) {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const targetElement = useRef<null | HTMLElement>(null);
    let hashName: string = hash.substring(1);
    useEffect(() => {
        console.log("scroll to : " + hashName);
        document.getElementById(hashName)?.scrollIntoView();

    }, [menuListSorted])

    useEffect(() => {
        function getPics() {
        } //just for this demo


        document.body.setAttribute("dir", ((defaultLocale == 'ar') ? "rtl" : "ltr"));

        const imgs = document.querySelectorAll('.menu-item img');

        const fullPage = document.querySelector('#fullpage') as HTMLElement;
        const fullPageImg = document.querySelector('#fullpage-img') as HTMLElement;

        imgs.forEach(img => {
            img.addEventListener('click', function () {
                if (fullPage != null) {
                    img = img as HTMLImageElement;
                    let imgsrc: string;
                    let imgsrcObj = img.getAttribute("aria-labelledby")
                    if ((imgsrcObj != null)) {
                        imgsrc = imgsrcObj
                    } else {
                        imgsrc = ""
                    }
                    fullPageImg.setAttribute("src", imgsrc);
                    fullPage.style.display = 'block';
                }
            });
        });
    });


    return (
        <div className='menu'>

            <div className={"header-section"} id={"header-1"}>
                <div className={"header-content"}>
                    <h2 className={"text-2xl"}>{config.t("main-menu")}</h2>
                </div>
            </div>
            <div className={"text-center flex align-middle flex-col md:flex-row"}>
                <div className={"w-full md:w-1/2"}><a href={"https://widget.servmeco.com/event/1441?oid=1011"}><img
                    className={"w-full"} src={(defaultLocale == "ar") ? BannerHappyHourAr : BannerHappyHour}/></a></div>
                <div className={"w-full md:w-1/2"}><a href={"https://widget.servmeco.com/event/1440?oid=1011"}><img
                    className={"w-full"} src={(defaultLocale == "ar") ? BannerLunchTwoAr : BannerLunchTwo}/></a></div>


            </div>

            <div id="categories" className="container mx-auto flex flex-wrap flex-row align-center">

                {
                    (dataCategories) ? (dataCategories?.map((item: Category, index) => (
                        <div className={"w-1/2 category-item-wrapper"} key={index}>
                            <a href={"menu#" + item.id + "cat"}>
                                <div className={"category-item "} key={item.id}>
                                    <p className={"p-2"}>{item.attributes.title}</p>
                                </div>
                            </a>
                        </div>
                    ))) : <div></div>
                }

            </div>
            <div>
                {
                    (Object.entries(categorySorted)) ? (Object.entries(categorySorted)?.map((catItem) => (
                        <div id="category-name" className="container mx-auto flex flex-wrap flex-row align-center" key={catItem[0]}>
                            <div className={"w-full text-center border-b-gold-full mb-5 pb-3"}>
                                <p id={catItem[0]} className={"text-medium font-bold"}>{catItem[1]}</p>
                            </div>
                            <br/>
                            {(menuListSorted[catItem[0]]) ? (menuListSorted[catItem[0]]?.map((item: MenuItem) => (
                                <div className={"menu-item w-full md:w-1/2 "} key={item.id}>
                                    <div className={"container menu-container mx-auto flex flex-row align-center p-4 "}>
                                        <div className={"h-full menu-img relative"}>
                                            <p className={"menu-tag"}>{item.attributes?.tag?.data?.attributes.title}</p>
                                            <img src={item.attributes?.image?.data?.attributes?.formats?.thumbnail?.url}
                                                 aria-labelledby={item.attributes?.image?.data?.attributes?.formats?.small?.url}
                                                 className={"w-full h-full"}/>

                                        </div>
                                        <div
                                            className={"menu-details container mx-auto flex flex-col align-center p-4"}>
                                            <div className={"flex-grow"}>
                                                <p className={"text-sm font-bold"}>{item.attributes.title}</p>
                                                <p className={"text-sm"}>{item.attributes.description}</p>
                                            </div>
                                            <div>
                                                <p className={"gold-color base-sm text-sm"}>{item.attributes.price_sar} S.R</p>
                                                <p className={"gold-color base-sm menu-cal text-sm"}>{item.attributes.calories} Cal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>))) : ""}

                        </div>
                    ))) : ""
                }
            </div>
            <div id="fullpage" onClick={handleFullscreenClick}>
                <div id={"fullpage-wrapper"} className={"pointer-events-none"}>
                    <img id="fullpage-img" className={"pointer-events-none"}/>
                </div>
            </div>

            <img className={"scroll-up-icon"} id={"scroll-up"} onClick={scrollToUp} src={ScrollUpIcon}/>
        </div>
    )
}

export default Menu