import {Children} from "react";
import {createBrowserRouter, RouterProvider, Outlet} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

import "./App.scss"
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Menu from "./pages/Menu/Menu";


const Layout = () => {

    return (
        <div className="app">
            <div id="control-height"></div>
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            }
        ],
    },
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                path: "/home",
                element: <Home/>,
            }
        ],
    },
    {
        path: "/menu",
        element: <Layout/>,
        children: [
            {
                path: "/menu",
                element: <Menu/>,
            }
        ],
    },
]);

function App() {
    return (
        <div>
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
