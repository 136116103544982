import React, {useState} from "react";

import {Link} from "react-router-dom";
import "./Navbar.scss"
import $ from 'jquery';
import logo from '../../assets/img/davanti_logo.png';
import {changeLanguage} from "../../helper";
import config from "../../i18n/config";

interface Navlink {
    title: string,
    link: string
}
const defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'en';

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const [sticky, setSticky] = useState(false)
    const data: Navlink[] = [{title: config.t("menu_home"), link: "home"},
        {title: config.t("menu_reservation"), link: "https://widget.servmeco.com/?oid=1011"},
        {title: config.t("menu_menu"), link: "menu"},
        {title: config.t("menu_restaurant_policy"), link: "home#restaurant-policy"},
        {title: config.t("menu_contact"), link: "home#contact"}
    ];

    function handleMenuToggle(){
        setOpen(!open)
    }
    React.useEffect(() => {
        var navbar = document.getElementById("navbar");
        var navbarJquery = $("#navbar");
        const toggleClass = "is-sticky";
        let lastScroll = 0;
        window.addEventListener("scroll", () => {
            const currentScroll = window.scrollY;

            if (currentScroll > lastScroll && currentScroll > 150) {
                lastScroll = currentScroll;

                navbarJquery.fadeOut();

            } else {
                navbarJquery.slideDown();
                lastScroll = currentScroll;
            }

            if (currentScroll > 150) {
                setSticky(true);
            } else {
                navbarJquery.show();
                setSticky(false);
            }
        });
    });


    return (
        <div className={`navbar ${open ? "bg-white" : ""} ${sticky ? "is-sticky" : ""}`} id={"navbar"}>
            <div className="wrapper">

                <div className="left">
                    <div className="item relative">
                        <Link to={"/"} style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0px",
                            left: "0px"
                        }}></Link>
                        <img src={logo} alt=""/>
                    </div>
                </div>


                <div className="right hidden md:block">

                </div>


                <div className="right">
                    <ul className="text-sm uppercase hidden md:block">
                        {(data) ? (data?.map((item) => (
                            <li key={item.title}><a href={item.link}>{item.title}</a></li>))) : <li></li>}

                    </ul>
                    <ul className="base-sm uppercase">
                        <li className={"font-bold cursor-pointer "} onClick={() => changeLanguage((defaultLocale == "en") ? "ar" : "en" )} ><a className={"sticky-color"}>{(defaultLocale == "en") ? "العربية" : "ENG"  }</a></li>
                    </ul>
                    <div className={`relative block md:hidden`}>
                        <div id="menu_button">
                            <input type="checkbox" id="menu_checkbox" onChange={handleMenuToggle}/>
                            <label htmlFor="menu_checkbox" id="menu_label">
                                <div id="menu_text_bar"></div>
                            </label>
                        </div>

                        <div className={`menu-mobile ${(defaultLocale == "ar") ? "menu-mobile-rtl" : ""} ${open ? "show" : "hide"}`}>
                            <ul>
                                {(data) ? (data?.map((item) => (
                                    <li key={item.title}><a href={item.link}>{item.title}</a></li>))) : <li></li>}
                            </ul>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    );
};

export default Navbar;