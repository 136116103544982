import React from 'react'
import "./Home.scss"

import OurStoryImg from '../../assets/img/ourstoryweb.jpg'
import OurMenuImg from '../../assets/img/ourmenuweb.jpg'
import ReservationImg from '../../assets/img/privateroom.jpg'
import RestaurantPolicyImg from '../../assets/img/restaurantpolicyweb.jpg'
import {Category} from "../../interfaces/category.interface"
import CategoryDummyImg from "../../assets/img/dummy/category/category_test.jpg"

import pinIcon from '../../assets/img/icons/pin_icon.png';
import phoneIcon from '../../assets/img/icons/phone_icon.png';
import emailIcon from '../../assets/img/icons/email_icon.png';
import config from "../../i18n/config";
import useFetch from "../../hooks/useFetch";
import ScrollUpIcon from "../../assets/img/icons/scroll_up.png";

const defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'en';

interface IProps {
}

interface IState {
    playOrPause?: string;
}

const Home = () => {

    var {data, loading, error} = useFetch(`/api/categories?populate=*&locale=${defaultLocale}&sort=display_order`);
    const dataCategories = data;
    function scrollToUp(e: React.MouseEvent<HTMLElement>) {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }


    return (
        <div className='home'>

            <div className={"header-section"} id={"header-1"}>
                <div className={"header-content"}>
                    <h2 className={"text-2xl"}>{config.t("experience-for-senses")}</h2>
                </div>
            </div>

            <div id="for-reservation" className="container-flow mx-auto flex flex-wrap flex-col md:hidden">
                <div className={"p-3 px-5 gold-background"}>
                    <p className={"text-white font-gotham-bold uppercase ltr"}>
                        {config.t("for-reservation")}
                    </p>
                </div>
                <div className={" px-5 py-5 grey-background"}>
                    <div className={"flex flex-wrap flex-row align-center pb-3"}>
                        <img src={phoneIcon} className={"icon"}/>
                        <p className={"left-to-right px-2"}>
                            <a href={"tel:+966148484448"}>+966-148484448</a>
                        </p>
                    </div>
                    <div className={"flex flex-row align-center pb-3 "}>
                        <img src={pinIcon} className={"icon"}/>
                        <p className={"px-2"}>
                            {config.t("location")}
                        </p>
                    </div>
                    <div className={"flex flex-wrap flex-row align-center pb-3"}>
                        <img src={emailIcon} className={"icon"}/>
                        <p className={"px-2"}>
                            <a href={"mailto:enquiries@davantilounge.sa"}>enquiries@davantilounge.sa</a>
                        </p>
                    </div>

                    <a href={"https://widget.servmeco.com/?oid=1011"}>
                        <div className={"button-secondary mt-4 inline-block"}>
                            <span>{config.t("book-table")}</span>
                        </div>
                    </a>
                </div>
            </div>

            <div id="our-story" className="container-flow mx-auto flex flex-wrap flex-row">
                <div className="xl:w-2/3 md:w-1/2 ">
                    <img src={OurStoryImg}/>
                </div>
                <div className="xl:w-1/3 md:w-1/2  flex items-center align-middle">
                    <div>
                        <div className=" px-12 pt-16 pb-16 lg:px-8 lg:pt-6 lg:pb-6 relative">
                            <h2 className={"text-2xl font-gotham-bold whitespace-pre leading-[3rem]"}><span
                                className={"grey-color uppercase"}>{config.t("our")}</span>{"\n"}
                                <span className={"gold-color uppercase"}>{config.t("story")}</span></h2>
                            <p className={"mt-4"}>{config.t("our-story-description")}</p>
                        </div>
                    </div>
                </div>
            </div>




            <div id="our-story" className="flex-col-reverse md:flex-row container-flow mx-auto flex flex-wrap">
                <div className="xl:w-1/3 md:w-1/2 flex items-center align-middle">
                    <div>
                        <div className="px-12 pt-16 pb-16 lg:px-8 lg:pt-6 lg:pb-6  relative">
                            <h2 className={"text-2xl font-gotham-bold whitespace-pre leading-[3rem]"}><span
                                className={"grey-color uppercase"}>{config.t("reservation")}</span></h2>

                            <a href={"https://widget.servmeco.com/?oid=1011"}>
                                <div className={"button-secondary mt-4 inline-block"}>
                                    <span>{config.t("book-table")}</span>
                                </div>
                            </a>

                            <a href={"https://widget.servmeco.com/event/1442?oid=1011"}>
                                <div className={"button-secondary mt-4 inline-block"}>
                                    <span>{config.t("book-table-vip")}</span>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
                <div className="xl:w-2/3 md:w-1/2">
                    <img src={ReservationImg}/>
                </div>

            </div>

            <div id="our-menu" className="container-flow mx-auto flex flex-wrap flex-row">


                <div className="xl:w-2/3 md:w-1/2">
                    <img src={OurMenuImg}/>
                </div>
                <div className="xl:w-1/3 md:w-1/2 flex items-center align-middle">
                    <div>
                        <div className=" px-12 pt-16 pb-16 lg:px-8 lg:pt-6 lg:pb-6 relative">
                            <h2 className={"text-2xl font-gotham-bold whitespace-pre leading-[3rem]"}><span
                                className={"grey-color uppercase"}>{config.t("our-menu")}</span>{"\n"}
                                <span className={"gold-color uppercase"}>{config.t("menu")}</span></h2>
                            <a href={"menu"}>
                                <div className={"button mt-4 inline-block"}>
                                    <span>{config.t("view-menu")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={"category-section container mx-auto flex flex-wrap flex-row items-center align-middle md:px-12 md:py-16"}>
                <div className={"text-center w-full pb-4"}>
                    <p className={"base"}> {config.t("fresh-food")}</p>
                    <h2 className={"font-gotham-bold text-xl gold-color"}>
                        {config.t("feel-extra")}
                    </h2>
                </div>
                <div className={"container w-full flex flex-wrap flex-row items-center align-middle"}>

                    {
                        dataCategories.map((item: Category) => {
                            return (
                                <div
                                    className={"w-1/2 lg:w-1/5 relative aspect-square flex justify-center items-center align-middle p-1"}>
                                    <div className={"w-full h-full"} style={{
                                        backgroundImage: `url("` + item.attributes?.image?.data?.attributes?.url + `")`,
                                        backgroundSize: "cover"
                                    }}>
                                        <a href={"menu#" + item.id + "cat"}>
                                            <div className={"p-4 h-full w-full"}>
                                                <div
                                                    className={"gold-background flex items-center align-middle justify-center"}>

                                                </div>
                                                <div className={"p-4 absolute top-0 left-0 h-full w-full flex items-center align-middle justify-center"}>
                                                    <div className={"p-2"}>
                                                        <p className={"text-medium-base font-bold text-white text-center"}> {item.attributes.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )

                        })

                    }

                </div>
            </div>

            <div id="restaurant-policy" className="container-flow mx-auto flex flex-wrap flex-col-reverse md:flex-row ">

                <div className="lg:w-1/3 flex items-center align-middle">
                    <div>
                        <div className=" px-12 pt-16 pb-16 relative">
                            <h2 className={"text-2xl font-gotham-bold whitespace-pre leading-[3rem]"}><span
                                className={"grey-color uppercase"}>{config.t("restaurant-pol")}</span>{"\n"}
                                <span className={"gold-color uppercase"}>{config.t("policy-pol")}</span></h2>
                            <div className={"mt-4"}>
                                <p className={"font-gotham border-b-gold"}>{config.t("dress-code")}</p>
                                <p className={"mt-3"}>{config.t("dress-code-description")}</p>
                            </div>

                            <div className={"mt-4"}>
                                <p className={"font-gotham fw-3 border-b-gold"}>{config.t("children-policy")}</p>
                                <p className={"mt-3"}>{config.t("children-policy-description")}</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="lg:w-2/3">
                    <img src={RestaurantPolicyImg}/>
                </div>
            </div>
            <img className={"scroll-up-icon"} id={"scroll-up"} onClick={scrollToUp} src={ScrollUpIcon}/>
        </div>
    )

}

export default Home