import React, {useState} from "react";

import {Link} from "react-router-dom";
import "./Footer.scss"
import $ from 'jquery';
import instaLogo from '../../assets/img/icons/instagram.svg';
import linkedinLogo from '../../assets/img/icons/linkedin.svg';
import logo from '../../assets/img/davanti_logo.png';
import pinIcon from '../../assets/img/icons/pin_icon.png';
import phoneIcon from '../../assets/img/icons/phone_icon.png';
import emailIcon from '../../assets/img/icons/email_icon.png';
import openingIcon from '../../assets/img/icons/opening_hour_icon.png';
import googleRating from '../../assets/img/google_rating_5_stars.jpg';
import config from "../../i18n/config";

const Footer = () => {

    return (
        <div className="footer container-flow grey-background" id={"contact"}>
            <div className={"w-full mx-auto flex flex-wrap flex-col md:flex-row items-center align-middle justify-center px-6 md:px-12 py-16"}>
                <a href={"/"}><img src={logo}/></a>
            </div>
            <div className={"container w-full  flex flex-col md:flex-row  px-6 md:px-12 py-8"}>
                <div className={"md:w-1/2 w-full mx-auto flex flex-wrap flex-row items-center align-middle justify-center"}>
                    <div className={"footer-div bg-white p-5 text-center h-full"}>
                        <p className={"text-l pb-5"}>{config.t("contact-us")}</p>
                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <a href={"https://maps.app.goo.gl/yMEA77w4UnVXru2WA?g_st=iw"}><img className={"pb-2 small"} src={pinIcon}/></a>
                            <a href={"https://maps.app.goo.gl/yMEA77w4UnVXru2WA?g_st=iw"}><p className={"whitespace-pre"}>{config.t("location")}</p></a>
                        </div>
                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <img className={"pb-2 small"} src={phoneIcon}/>
                            <p className={"whitespace-pre left-to-right"}><a href={"tel:+966148484448"}>+966148484448</a></p>
                        </div >
                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <img className={"pb-2 small"} src={emailIcon}/>
                            <a className={"left-to-right"} href={"mailto:enquire@davantilounge.sa"}>enquire@davantilounge.sa</a>
                        </div>
                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <a href={"https://www.instagram.com/davanti_lounge"}><img className={"invert"} src={instaLogo}/></a>
                        </div>

                    </div>
                </div>
                <div className={"md:w-1/2 w-full mx-auto flex flex-wrap flex-row items-center align-middle justify-center"}>
                    <div className={"footer-div  bg-white p-5 text-center h-full"}>
                        <p className={"text-l pb-5"}>{config.t("working-hours")}</p>
                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <img className={"pb-2 small"} src={openingIcon}/>
                            <p className={"whitespace-pre pt-4"}>{config.t("working-hours-description")}</p>
                        </div>

                        <div className={"text-center p-3 flex flex-wrap flex-col items-center align-middle justify-center"}>
                            <a href={"https://maps.app.goo.gl/yMEA77w4UnVXru2WA?g_st=iw"}><img className={"pb-2 w-full mw-300"} src={googleRating}/></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;