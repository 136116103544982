import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {changeLanguage} from "./helper";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'en';
const query = new URLSearchParams(window.location.search);
const language = query.get('lang')
query.delete('lang')
if(language != null){
    if(language == "ar"){
        changeLanguage("ar");
    }else{
        changeLanguage("en");
    }
}

root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const appHeight = () => {
    const doc = document.documentElement
    let recommendedHeight = window.document.documentElement.clientHeight;
    doc.style.setProperty('--app-height', `${recommendedHeight}px`)
    console.log("space available outer: " + recommendedHeight);
}




appHeight();
document.body.setAttribute("dir", ((defaultLocale == 'ar') ? "rtl" : "ltr"));
window.addEventListener('resize', appHeight);

